// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_4-LEk{align-items:center;height:100vh;justify-content:center;width:100vw}.container_4-LEk,.inner_Hh421{display:flex;flex-direction:column}.inner_Hh421{width:32rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_4-LEk",
	"inner": "inner_Hh421"
};
module.exports = ___CSS_LOADER_EXPORT___;
