
import ForgotPassword from "~/graphql/Authentication/ForgotPassword.gql";

export default {
    layout: "login",
    data() {
        return {
            email: "",
            emailErrorText: "",
            emailError: false,
            resetButtonClicked: false,
            emailSent: false,
        };
    },
    created() {},
    methods: {
        reset() {
            this.resetButtonClicked = true;
            this.emailErrorText = "";
            this.emailError = false;

            this.$apollo
                .query({
                    query: ForgotPassword,
                    variables: { email: this.email },
                })
                .then(() => {
                    this.emailSent = true;
                })
                .catch((err) => {
                    console.error(err);
                    if (err.message.includes("user not found")) {
                        this.emailErrorText = this.$t("RESET_USER_NOT_FOUND");
                        this.emailError = true;
                    }
                    this.resetButtonClicked = false;
                });
        },
    },
};
